import React, { useState, useEffect } from "react";

const TypeWriter = ({ text, speed = 150, eraseSpeed = 50, delay = 3000 }) => {
	const [displayedText, setDisplayedText] = useState("");
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		const handleTyping = () => {
			const currentText = isDeleting
				? text.substring(0, displayedText.length - 1)
				: text.substring(0, displayedText.length + 1);

			setDisplayedText(currentText);

			if (!isDeleting && currentText === text) {
				setTimeout(() => setIsDeleting(true), delay);
			} else if (isDeleting && currentText === "") {
				setIsDeleting(false);
			}
		};

		const timeout = setTimeout(handleTyping, isDeleting ? eraseSpeed : speed);

		return () => clearTimeout(timeout);
	}, [displayedText, isDeleting, text, speed, eraseSpeed, delay]);

	return (
		<span className="typewriter">
			{displayedText}
			<span className="typewriter-cursor">|</span>
		</span>
	);
};

export default TypeWriter;
