import React from 'react'

const Tag = ({ tag }) => {
    return (
        <div className="text-xs w-fit items-center font-bold leading-sm uppercase px-2 md:px-3 py-1 bg-blue-200 text-blue-700 rounded-full">
            {tag}
        </div>
    )
}

export default Tag