import React from "react";
import Tag from "./Tag";
import { RiExternalLinkLine } from "react-icons/ri";

const ProjectCard = ({ title, link, tags, description }) => {
	return (
		<div className="w-full relative md:w-4/5 mx-auto card flex flex-col shadow-lg border border-slate-400">
			<a href={link} target="_blank" rel="noreferrer">
				<div className="justify-between flex items-center group">
					<p className="text-xl font-medium md:text-4xl text-black">{title}</p>
					<RiExternalLinkLine
						className={`fill-black w-6 h-6 cursor-pointer transition-transform duration-300 group-hover:scale-125`}
					/>
				</div>
			</a>
			<div className="flex flex-wrap w-full overflow-x-hidden gap-2 mt-2">
				{tags.map((tag) => (
					<Tag tag={tag} key={title} />
				))}
			</div>
			<p className="mt-3 font-light md:font-normal">{description}</p>
		</div>
	);
};

export default ProjectCard;
