import Navbar from "./Components/Navbar";
import About from "./Components/About";
import Projects from "./Components/Projects";
import Contact from "./Components/Contact";

function App() {
	return (
		<div className="w-full flex-col">
			{/* Navbar */}
			<Navbar />
			{/* about */}
			<About />
			{/* Projects */}
			<Projects />
			{/* Footer */}
			<Contact />
		</div>
	);
}

export default App;
