import React from "react";
import projectsData from "../projectsData";
import ProjectCard from "./ProjectCard";

const Projects = () => {
	return (
		<div
			id="projects"
			className="w-11/12 justify-center mx-auto md:w-4/5 py-5 md:mx-auto flex flex-col">
			<p className="text-4xl md:text-5xl text-black font-semibold">Projects</p>
			<p className="md:text-2xl text-white mt-2 md:mt-4">
				You can find some of my projects here, view the code I write on{" "}
				<span className="underline text-black">
					{" "}
					<a
						href="https://github.com/avikaldarolia"
						target="_blank"
						rel="noreferrer">
						Github
					</a>
				</span>{" "}
				or head over to{" "}
				<span className="underline text-black">
					{" "}
					<a
						href="https://www.linkedin.com/in/avikaldarolia/"
						target="_blank"
						rel="noreferrer">
						LinkedIn
					</a>
				</span>{" "}
				to go through my experiences.
			</p>

			<div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-4 md:mt-10">
				{projectsData.map((item) => (
					<ProjectCard
						tags={item.tags}
						key={item.id}
						title={item.title}
						link={item.link}
						description={item.description}
					/>
				))}
			</div>
		</div>
	);
};

export default Projects;
