const projectsData = [
    {
        id: 1,
        title: 'SoilTrackers',
        link: 'https://www.soiltrackers.com/',
        tags: ["NestJs", "ReactJs", "RabbitMQ", "Redis", "AWS"],
        description: "SoilTrackers have a state-of-the-art tracking system. This system meticulously traces and records the movement of soil, waste materials, and invasive species, ensuring transparency and adherence to municipal regulations."
    },
    {
        id: 2,
        title: 'Pillup',
        link: 'https://pillup.com/',
        tags: ["Reactjs", "Nodejs", "PostgreSQL", "Express", "Redis", "AWS"],
        description: 'PillUp is an online pharmacy that sorts your medications into easy to tear pouches by time and date and delivers them to your door.'
    },
    {
        id: 3,
        title: 'Movie',
        link: 'https://github.com/avikaldarolia/movie-frontend',
        tags: ["Reactjs", "Cookies", "Nodejs", "PostgreSQL", "Express", "TailwindCSS"],
        description: 'A basic portal for movie/anime fanatics. Friend Request System implemented along with basic CRUD of making playlists.'
    },
    {
        id: 4,
        title: 'Instagram Clone',
        link: 'https://insta-gram-clone-avikaldarolia.vercel.app/login',
        tags: ["Reactjs", "TailwindCSS", "Firebase"],
        description: 'Cloned Instagram using React and TailWind CSS and deployed it using Firebase. Users can like/dislike and comment on posts and follow other users as well.'
    }
]

export default projectsData