import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { ImTwitter } from "react-icons/im";
import { SiGmail } from "react-icons/si";
import { FaSquareXTwitter } from "react-icons/fa6";

const Contact = () => {
	return (
		<div className="w-11/12 md:w-4/5 mx-auto flex flex-col py-5 md:py-10">
			<p className="text-4xl md:text-5xl text-black font-semibold">
				Let's work together
			</p>
			<p className="md:text-2xl text-white mt-2 md:mt-4">
				I’d love to talk with you about your next creative endeavor. Send me a
				note, let’s start a new project together.
			</p>
			<div className="flex justify-between items-center mt-5">
				<a
					href="https://www.linkedin.com/in/avikaldarolia/"
					target="_blank"
					rel="noreferrer">
					<div className="flex items-center hover:scale-110 px-6">
						<BsLinkedin className="w-8 md:w-10 h-8 md:h-10" />
						<div className="hidden md:block md:ml-5 my-1 flex-col">
							<p className="font-semibold">LinkedIn</p>
							<p>/avikaldarolia</p>
						</div>
					</div>
				</a>
				<a href="mailto:avikaldarolia7@gmail.com">
					<div className="flex items-center hover:scale-110">
						<SiGmail className="w-8 md:w-10 h-8 md:h-10" />
						<div className="hidden md:block ml-5 my-1 flex-col ">
							<p className="font-semibold">Gmail</p>
							<p>@avikaldarolia7</p>
						</div>
					</div>
				</a>
				<a
					href="https://twitter.com/DaroliaAvikal"
					target="_blank"
					rel="noreferrer">
					<div className="flex items-center hover:scale-110">
						<FaSquareXTwitter className="w-8 md:w-10 h-8 md:h-10" />
						<div className="hidden md:block ml-5 my-1 flex-col">
							<p className="font-semibold">Twitter</p>
							<p>@DaroliaAvikal</p>
						</div>
					</div>
				</a>
			</div>
		</div>
	);
};

export default Contact;
